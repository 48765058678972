import { Typography } from 'antd';
import React from 'react';
const { Paragraph } = Typography;

export default function Give5Modal(store) {
  return (
    <div>
      <p className="mb-8">
        Share your personal coupon to earn $5 each time a new friend buys
        parking with us. We'll email you to let you know when you've earned your
        $5!
      </p>
      <Paragraph copyable={{ text: store.user.coupon_member }}>
        Click to copy the coupon code:{' '}
        <strong>{store.user.coupon_member}</strong>
      </Paragraph>
      <Paragraph
        copyable={{
          text: `https://www.onairparking.com/CouponCode/${store.user.coupon_member}`,
        }}
      >
        Click to copy the link for sharing:{' '}
        <strong>
          https://www.onairparking.com/CouponCode/{store.user.coupon_member}
        </strong>
      </Paragraph>
    </div>
  );
}
