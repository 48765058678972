import { ButtonProps, Modal } from 'antd';
import { ReactElement } from 'react';

type ModalProps = {
  type: string;
  title?: string;
  centered?: boolean;
  content?: ReactElement | string;
  onOk?: Function;
  okText?: string;
  onCancel?: Function;
  cancelText?: string;
  okButtonProps?: ButtonProps;
  keyboard?: boolean;
  afterClose?: Function;
  hold?: Function;
};

export const modalType = ({
  type,
  title,
  centered,
  content,
  okText,
  onOk,
  onCancel,
  cancelText,
  okButtonProps,
  keyboard,
  afterClose,
}: ModalProps) =>
  Modal[type]({
    title,
    content,
    centered,
    okText,
    onOk,
    onCancel,
    cancelText,
    okButtonProps,
    keyboard,
    afterClose,
  });
