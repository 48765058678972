import React, { useState, useEffect } from 'react';
import { CDN1, CDN2 } from '@/helpers/api';
import { Button, Drawer, Empty, Spin } from 'antd';
import { useMobxStores } from '@/stores';
import { useRouter } from 'next/router';
import usePrefix from '@/hooks/usePrefix';
import { IMyFacility, IReservation } from '@/types';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import Link from 'next/link';
import { CloseOutlined } from './Icons';
import SearchImage from './SearchImage';

interface IDrawerRebook {
  closeMenu?: Function;
}

export function DrawerRebook({ closeMenu }: IDrawerRebook) {
  const { myReservationStore } = useMobxStores();
  const router = useRouter();
  const prefix = usePrefix();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [myFacilities, setMyFacilities] = useState<IMyFacility[]>(null);

  useEffect(() => {
    if (isDrawerOpen) {
      myReservationStore.loadMyFacilities().then((myreservations) => {
        setMyFacilities(myReservationStore.myFacilities);
      });
    }
  }, [isDrawerOpen]);

  const handleOk = () => {
    setIsDrawerOpen(true);
  };

  const handleCancel = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="flex flex-col drawerRebook">
      <a
        onClick={handleOk}
        className="text-primary font-bold lg:!text-sm md:!text-xs !text-sm "
      >
        My Parking Spots
      </a>
      <Drawer
        visible={isDrawerOpen}
        onClose={handleCancel}
        destroyOnClose={true}
        title={<p className="text-center font-bold">My Parking Spots</p>}
        footer={
          <>
            <p
              className="text-secondary font-bold cursor-pointer text-center center"
              onClick={handleCancel}
            >
              Close <CloseOutlined className="mb-2" />
            </p>
          </>
        }
      >
        <div>
          {!myFacilities ? (
            <div className="flex justify-center">
              <Spin tip="loading" className="mt-2" />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-2 mx-auto">
                {myFacilities?.map((data: IMyFacility) => (
                  <>
                    <Link
                      href={`${prefix}/parkingat/${
                        data?.facility_url_code
                      }?checkin=${dayjs().format(
                        'YYYY-MM-DD'
                      )}&checkout=${dayjs()
                        .add(1, 'day')
                        .format('YYYY-MM-DD')}&edit=true`}
                      type="primary"
                      className="cursor-pointer hover:text-primary border border-gray-200 m-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDrawerOpen(false);
                        if (closeMenu) {
                          closeMenu();
                        }
                      }}
                      key={data.facility_id}
                    >
                      <div className="flex flex-col items-center m-2">
                        <div className="relative w-16 h-16 rounded-full overflow-hidden">
                          <SearchImage
                            src={
                              data.facility_is_image_uploaded
                                ? `${CDN1}/upload/${
                                    myReservationStore.environment ?? 0
                                  }/${data.facility_id}`
                                : CDN2() + '/staticmyapp/default-image.png'
                            }
                            // src={CDN2() + '/staticmyapp/default-image.png'}
                            errorImage={
                              CDN2() + '/staticmyapp/default-image.png'
                            }
                            quality={100}
                            placeholder="blur"
                            className="z-0 object-fill"
                            fill
                            alt={`Airport: ${data.facility_lot} Background`}
                          />
                        </div>
                        <p className="text-xs text-center font-bold mt-2">
                          {data.facility_lot}
                        </p>
                        <p className="text-[0.625rem] leading-[0.875rem] text-center mt-1">
                          {data.facility_address}
                        </p>
                      </div>
                    </Link>
                  </>
                ))}
              </div>
              {myFacilities.length === 0 && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="You have no past booking history"
                />
              )}
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default observer(DrawerRebook);
