import React, { useState } from 'react';
import { Drawer, Menu } from 'antd';
import Link from 'next/link';
import usePrefix from '@/hooks/usePrefix';
import { pushLayerEvent } from '@/utils/gtag';
import { root } from 'postcss';

interface IAirportDrawer {
  isLogged?: any;
  airports: any[];
}

const { SubMenu } = Menu;

export const categorizeAirports = (airports) => {
  const categories = {};

  airports?.forEach((airport) => {
    const category = airport.airport_level1;
    if (category) {
      if (!categories[category]) {
        categories[category] = [];
      }
      categories[category].push(airport);
    }
  });

  const rootCategories = [
    'Northeast',
    'Southeast',
    'Midwest',
    'Southwest',
    'West',
  ];

  for (const f of Object.keys(categories)) {
    if (rootCategories.indexOf(f) == -1) {
      rootCategories.push(f);
    }
  }

  return { categories, rootCategories };
};

const AirportDrawer = ({ airports, isLogged }: IAirportDrawer) => {
  const prefix = usePrefix();
  const [open, setOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setOpenKeys([]);
  };

  const onSubMenuClick = (key) => {
    setOpenKeys((prevOpenKeys) => {
      if (prevOpenKeys.includes(key)) {
        return [];
      } else {
        return [key];
      }
    });
  };

  const { categories, rootCategories } = categorizeAirports(airports);

  return (
    <>
      <li
        onClick={showDrawer}
        className={`border-none font-bold mx-2 text-primary ${
          isLogged ? '!text-sm' : '!text-base'
        }  cursor-pointer`}
      >
        Airports
      </li>
      <Drawer title="Airports" closable={true} onClose={onClose} visible={open}>
        <Menu
          mode="inline"
          openKeys={openKeys}
          className="!border-none"
          onOpenChange={(keys) => {
            if (keys.length > 1) {
              setOpenKeys([keys[keys.length - 1]]);
            } else {
              setOpenKeys(keys);
            }
          }}
        >
          {rootCategories.map((category, index) => (
            <SubMenu
              key={category}
              title={category}
              onTitleClick={() => onSubMenuClick(category)}
            >
              {categories[category]?.map((airport, subIndex) => (
                <Menu.Item key={`${category}-${subIndex}`}>
                  <Link
                    href={`/parking/${airport.airport_frienldy_url}`}
                    onClick={() => {
                      pushLayerEvent('clickAirport', {
                        facility_id: airport.airport_name,
                      });
                      onClose();
                    }}
                  >
                    {airport.airport_name} Parking ({airport.airport_initials})
                  </Link>
                </Menu.Item>
              ))}
              <Menu.Divider />
            </SubMenu>
          ))}
          <Menu.Item key="all-airports">
            <Link href={`${prefix}/airports`}>All Airport Parking</Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default AirportDrawer;
