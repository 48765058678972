import { Button, Divider, Dropdown, Menu, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CloseOutlined,
  ArrowLeftOutlined,
  DownOutlined,
  ScheduleOutlined,
  UserOutlined,
  MobileOutlined,
} from '@/components2/Icons';
import { useMobxStores } from '@stores/index';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { modalType } from './Modal';
import Give5Modal from './Give5Modal';
import usePrefix from '@/hooks/usePrefix';
import { DrawerRebook } from './DrawerRebook';

const menu = (store, prefix) => {
  function handleOnClick() {
    modalType({
      type: 'info',
      title: 'Give $5 and Get $5!',
      content: Give5Modal(store.authStore),
    });
  }
  return (
    <Menu
      key="1"
      id="dropdown-user-menu"
      className="flex flex-col gap-y-2 w-full h-auto"
    >
      <Menu.Item className="!mt-2" key="1.1">
        <Link
          href={prefix + '/myreservation'}
          onClick={() => store.uiStore.toggleMenu()}
        >
          My Reservations
        </Link>
      </Menu.Item>
      <Menu.Item key="1.2">
        <Link
          href={prefix + '/myreservation/account'}
          onClick={() => store.uiStore.toggleMenu()}
        >
          My Account
        </Link>
      </Menu.Item>
      {/* <Menu.Item
        key="1.3"
        className="font-normal text-md"
        onClick={handleOnClick}
      >
        <a onClick={() => store.uiStore.toggleMenu()}>Give 5 Get 5</a>
      </Menu.Item> */}
      <Divider type="horizontal" className="!-mt-1 !mb-1" />
      <Menu.Item key="1.4">
        <Link href="/logout" onClick={() => store.uiStore.toggleMenu()}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default function MenuMobile() {
  const { uiStore, authStore } = useMobxStores();
  const user = authStore.user;
  const [loggedIn, setLoggedIn] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setLoggedIn(authStore.isSignedIn());
  }, [authStore]);
  const prefix = usePrefix();
  return (
    <div
      className={`w-full h-auto overflow-hidden bg-white z-50 relative top-0 right-0 px-0`}
    >
      <Divider type="horizontal" className="!-mt-1 !mb-1" />
      {!loggedIn ? (
        <>
          <div className={`flex md:hidden justify-evenly my-6`}>
            <Link
              href={prefix + '/login'}
              passHref
              className="bg-white text-base font-bold text-primary py-2.5 px-12 text-center hover:text-primary rounded-full border border-primary sign-in"
              onClick={() => uiStore.toggleMenu()}
            >
              Sign In
            </Link>
            <Link
              href={prefix + '/signup'}
              passHref
              className="bg-primary text-base font-bold text-white py-2.5 px-12 text-center hover:text-primary rounded-full  sign-up"
              onClick={() => uiStore.toggleMenu()}
            >
              Sign Up
            </Link>
          </div>
          <Divider type="horizontal" className="!-mt-1 !mb-1" />

          <div className={`flex md:hidden justify-evenly my-6`}>
            <Link
              href="/blog"
              className="text-primary text-base font-bold  hover:text-primary"
              onClick={() => uiStore.toggleMenu()}
            >
              Blog
            </Link>
          </div>
          <Divider type="horizontal" className="!-mt-1 !mb-1" />

          <div className={`flex md:hidden justify-evenly my-6`}>
            <Link
              href="/contact"
              className="text-primary text-base font-bold  hover:text-primary"
              onClick={() => uiStore.toggleMenu()}
            >
              Contact
            </Link>
          </div>
          <Divider type="horizontal" className="!-mt-1 !mb-1" />
        </>
      ) : (
        <>
          <Button
            type="text"
            className="flex flex-col items-center text-sm focus:outline-none my-6 w-full"
            id="user-menu"
            aria-haspopup="true"
          >
            <Dropdown
              overlay={menu({ uiStore, authStore }, prefix)}
              placement="bottomCenter"
              trigger={['click']}
              forceRender
            >
              <div className="flex flex-row w-full justify-center items-center">
                <p className=" text-sm">
                  Hi, {authStore.user.member_first_name}
                </p>
                {/* <Gravatar
                          email={authStore.user.member_login}
                          size={32}
                          className="rounded-full border"
                          default="mp"
                        /> */}
                <DownOutlined className="ml-auto" />
              </div>
            </Dropdown>
          </Button>
          <Divider type="horizontal" className="!-mt-1 !mb-1" />
          <div className={`flex md:hidden justify-evenly my-6`}>
            <Link
              href={prefix + '/city'}
              className="text-primary text-base font-bold  hover:text-primary"
              onClick={() => uiStore.toggleMenu()}
            >
              City
            </Link>
          </div>
          <Divider type="horizontal" className="!-mt-1 !mb-1" />
          <div className={`flex md:hidden justify-evenly my-6`}>
            <Link
              href={prefix + '/cruise'}
              className="text-primary text-base font-bold  hover:text-primary"
              onClick={() => uiStore.toggleMenu()}
            >
              Cruise
            </Link>
          </div>
          <Divider type="horizontal" className="!-mt-1 !mb-1" />
          <div className={`flex md:hidden justify-evenly my-6`}>
            <Link
              href="/blog"
              className="text-primary text-base font-bold  hover:text-primary"
              onClick={() => uiStore.toggleMenu()}
            >
              Blog
            </Link>
          </div>
          <Divider type="horizontal" className="!-mt-1 !mb-1" />
          <div className={`flex md:hidden justify-evenly my-6`}>
            <Link
              href="/contact"
              className="text-primary text-base font-bold  hover:text-primary"
              onClick={() => uiStore.toggleMenu()}
            >
              Contacts
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
