import Image from 'next/image';
import { useState } from 'react';

function SearchImage({
  alt,
  src,
  errorImage,
  placeholder,
  quality,
  className,
  fill,
  native = false,
}) {
  const [newSrc, setNewSrc] = useState(src);

  return !native ? (
    <Image
      src={newSrc}
      alt={alt} // To fix lint warning
      onError={() => {
        setNewSrc(errorImage);
      }}
      fill
      sizes="100vw, 50vw"
      placeholder={placeholder}
      blurDataURL={errorImage}
      quality={quality}
      className={className}
      loading="eager"
    />
  ) : (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={`${newSrc}?q=${quality}`}
      alt={alt}
      className={className}
      onError={() => {
        setNewSrc(errorImage);
      }}
      loading="eager"
    />
  );
}

export default SearchImage;
