import React, { useState } from 'react';
import usePrefix from '@/hooks/usePrefix';
import Link from 'next/link';
import { DownOutlined, RightOutlined } from './Icons';
import { Dropdown } from 'antd';
import { pushLayerEvent } from '@/utils/gtag';
import { categorizeAirports } from './AirportsDrawer';

const UserMenu = ({ airports, prefix, onCloseMenu }) => {
  const { categories, rootCategories } = categorizeAirports(airports);
  const [openSubMenu, setOpenSubMenu] = useState('');

  const toggleSubMenu = (category) => {
    setOpenSubMenu((prevSubMenu) => (prevSubMenu === category ? '' : category));
  };

  const handleMouseEnter = (category) => {
    setOpenSubMenu(category);
  };

  const handleMouseLeave = () => {
    setOpenSubMenu('');
  };

  const handleLinkClick = () => {
    setOpenSubMenu('');
    onCloseMenu();
  };

  return (
    <div className="absolute bg-white shadow-lg rounded-b-lg z-50 mt-2">
      {rootCategories.map((category, index) => (
        <>
          <div
            key={index}
            className={`py-1.5 px-3 md:w-28 lg:w-32 xl:w-40 
              ${openSubMenu === category && ' bg-[#fbac66]'}`}
            onMouseEnter={() => handleMouseEnter(category)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="mb-2 relative">
              <div
                onClick={() => toggleSubMenu(category)}
                className={`flex justify-between items-center cursor-pointer ${
                  openSubMenu === category && ' bg-[#fbac66]'
                }`}
              >
                <span className="md:text-[0.5rem] lg:text-[0.625rem] xl:text-sm">
                  {category}
                </span>
                <RightOutlined className="md:text-[0.5rem] lg:text-[0.625rem] xl:text-sm" />
              </div>
            </div>
          </div>
        </>
      ))}

      <div className="py-1.5 px-3 hover:bg-[#fbac66]">
        <Link
          href={`${prefix}/airports`}
          onClick={() => handleLinkClick()}
          className="md:text-[0.5rem] lg:text-[0.625rem] xl:text-sm"
        >
          All Airport Parking
        </Link>
      </div>
      {rootCategories.map(
        (category) =>
          openSubMenu === category && (
            <>
              <div
                className="absolute left-full top-0 shadow-lg rounded-b-lg z-50 bg-white min-w-max"
                onMouseEnter={() => setOpenSubMenu(category)}
                onMouseLeave={() => setOpenSubMenu('')}
              >
                <div className="grid grid-cols-2">
                  {categories[category]?.map((airport, subIndex) => (
                    <>
                      <Link
                        key={`${subIndex}`}
                        className={`p-2  text-onair-gray md:text-[0.5rem] lg:text-[0.625rem] xl:text-sm ${
                          openSubMenu === category && 'hover:bg-[#fbac66]'
                        } `}
                        href={`/parking/${airport.airport_frienldy_url}`}
                        onClick={() => {
                          handleLinkClick();
                          pushLayerEvent('clickAirport', {
                            facility_id: airport.airport_name,
                          });
                        }}
                      >
                        {`${airport.airport_name} Parking (${airport.airport_initials})`}
                      </Link>
                    </>
                  ))}
                </div>
              </div>
            </>
          )
      )}
    </div>
  );
};

export default function AirportDropdown({ airports }) {
  const prefix = usePrefix();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Dropdown
        visible={menuOpen}
        overlay={
          <UserMenu
            airports={airports}
            prefix={prefix}
            onCloseMenu={handleMenuClose}
          />
        }
        onVisibleChange={(visible) => setMenuOpen(visible)}
      >
        <a className="text-primary font-bold lg:!text-sm !text-xs">
          Airports <DownOutlined />
        </a>
      </Dropdown>
    </>
  );
}
